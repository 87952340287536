export class MessageIterator {
  constructor() {
    this.pullQueue = [];
    this.pushQueue = [];
    this.running = true;
  }

  [Symbol.asyncIterator]() {
    return this;
  }

  async next() {
    return this.pull();
  }

  pull() {
    return new Promise(resolve => {
      if (this.pushQueue.length !== 0) {
        resolve(this.running
          ? { value: this.pushQueue.shift(), done: false }
          : { value: undefined, done: true });
      } else {
        this.pullQueue.push(resolve);
      }
    });
  }

  push(event) {
    if (this.pullQueue.length !== 0) {
      this.pullQueue.shift()(this.running
        ? { value: event, done: false }
        : { value: undefined, done: true });
    } else {
      this.pushQueue.push(event);
    }
  }
}
