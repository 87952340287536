<template>
  <div class="admin-page-wrapper">
    <v-row class="admin-header-nav">
      <admin-tabs />
      <v-spacer></v-spacer>
      <!-- <button type="button" class="admin-btn-update" @click="onUpdateDataPlayers">
        Update
      </button> -->
      <div class="header-action"></div>
      <div class="header-search pl-5">
        <v-text-field
          v-model="search"
          clearable
          color="blur"
          solo
          dense
          hide-details
          clear-icon="mdi-close"
          prepend-inner-icon="mdi-magnify"
          label="Search for value"
        ></v-text-field>
      </div>
    </v-row>
    <v-data-table
      :custom-sort="customSort"
      group-by="cluster"
      :headers="headers"
      :items="servers"
      :items-per-page="itemsPerPage"
      :page="page"
      :search="search"
      :loading="isLoading"
      loading-text="Loading... Please wait"
      item-key="username"
      :footer-props="{
        'items-per-page-options': [10, 20, 30],
      }"
      @click:row="onClickServer"
      class="data-table__users"
    >

      <template v-slot:group.header="{items}" style="background: #FFFFFF;">
        <th :colspan="headers.length" v-if="items.find(x => x.cluster !== '')">
          <span class="table-group-header">
           📁 {{ items[0].cluster.toUpperCase() }}
          </span>
          <span v-if="items.find(x => x.status === 'online')" class="table-cluster-edit" @click="openEditACL(items[0])">
            <span class="text-link table-cluster-edit-text">Edit</span>
            <span class="table-cluster-edit-icon">📝</span>
          </span>
        </th>
      </template>

      <template v-slot:group.summary>
        <th :colspan="headers.length" style="height: 5px !important;">
        </th>
      </template>

      <template #item.ServerAddress="{ item }">
        <a
          :href="`http://${logUsername}${item.ServerAddress}:8888`"
          target="_blank"
          class="text-decoration-none"
        >
          <span @click.stop
            >{{ item.ServerAddress }}
            <v-icon small>mdi-open-in-new </v-icon>
          </span>
        </a>
      </template>

      <template #item.Caption="{ item }">
        <span :class="item.cluster ? 'table-cluster-row' : ''">
          🖥 {{ item.Caption }}
        </span>
      </template>

      <template #item.status="{ item }">
        <span
          v-if="item.status !== 'loading'"
          :class="item.status === 'online' ? 'online' : 'offline'"
        >
          <span v-if="item.status === 'online'">🟢</span>
          <span v-else>❌</span>
          {{ item.status }}
        </span>
        <span class="spinner" v-else>
          <spinner-loader />
        </span>
      </template>

      <template #item.users="{ item }">
        <span v-if="item.users">{{ item.users }} </span>
        <span class="spinner" v-else>
          <spinner-loader />
        </span>
      </template>

      <template v-slot:item.action="{ item }">
        <!-- v-if="item.status === 'online'" -->
        <span v-if="!item.cluster && item.status === 'online'" class="table-cluster-edit" @click="openEditACL(item)">
            <span class="text-link table-cluster-edit-text">Edit</span>
            <span class="table-cluster-edit-icon">📝</span>
        </span>
      </template>

      <!-- <template v-slot:item.update="{ item }">
        <span @click="reloadAclConfig(item)"
          >Update <v-icon small>mdi-update</v-icon></span
        >
      </template> -->
    </v-data-table>
    <!-- Edit Access Policy Popup -->
    <edit-acl-dialog
      v-if="aclDialogVisible"
      :dialog-visible="aclDialogVisible"
      :server-name="serverName"
      :server-address="serverAddress"
      :serverData="openedServer"
      :clusterServers="clusterServers"
      :users="usersOf"
      :acl-data="serverRoles"
      @close="closeAclDialog"
    />
  </div>
</template>

<script>
import awsAuth from "@/cognito/aws-auth";
import { MessageIterator } from "@/plugins/message-iterator";
// import request from "@/plugins/request";

import AdminTabs from "../AdminTabs";
import EditAclDialog from "./dialogs/EditAclDialog.vue";
import SpinnerLoader from "./StatusLoader.vue";
import { mapActions } from "vuex";

import { Constants } from "@/config/constants";

export default {
  components: {
    AdminTabs,
    EditAclDialog,
    SpinnerLoader,
  },
  data() {
    return {
      filter: {},
      sortBy: "Caption",
      sortDesc: false,
      page: 1,
      itemsPerPage: 10,
      headers: Constants.ServerTableHeaders,
      logUsername: Constants.ServerLogUsername,
      servers: [],
      clusterServers: [],
      clusters: new Map(),
      serverName: null,
      serverAddress: null,
      search: "",
      errorMessage: null,
      isLoading: true,
      rules: [],
      json: null,
      aclDialogVisible: false,
      openedServer: null,
      usersOf: [],
      serverRoles: [],
      createdInterval: null,
    };
  },
  mounted() {
    this.fetchData();
    this.updateServersData();
  },
  methods: {
    ...mapActions("ui", ["snackbar"]),
    async fetchData() {
      try {
        const response = await awsAuth.getAllServers();

        response.Clusters.map(cluster => {
          for (let srv of cluster.Servers) {
            this.clusters.set(srv.toLowerCase(), cluster.Id.toLowerCase());
          }
        })

        this.servers = response.Servers.map((obj) => {
          obj.status = "loading";
          obj.cluster = this.clusters.get(obj.Id.toLowerCase()) || "";

          return obj;
        });

        this.isLoading = false;
        this.updateData();
      } catch (err) {
        console.log(err);
      }
    },
    async updateData() {
      const iterator = new MessageIterator();
      this.servers.map((obj) => {
        iterator.push(obj.ServerAddress);
      });

      for await (const data of iterator) {
        await this.updateServerStatus(data);
      }
    },
    async updateServerStatus(serverLink) {
      const serverInfo = await awsAuth.getServerStatus(serverLink);
      const newServer = this.servers.find(
        (obj) => obj.ServerAddress === serverInfo.ServerAddress
      );
      newServer.status = serverInfo.status;
      newServer.users = serverInfo.users;
    },
    updateServersData() {
      this.createdInterval = setInterval(() => {
        this.updateData();
      }, 10e5);
    },
    onClickServer(item) {
      if (!this.aclDialogVisible)
        this.$router.push(`/admin/servers/${item.Caption}`);
    },
    async openEditACL(item) {
      this.clusterServers = [];
      this.serverName = item.cluster || item.Caption.replace(" ", "_").toLowerCase();
      this.serverAddress = item.ServerAddress;

      if (item.cluster) {
        this.clusterServers = this.servers.filter(x => x.cluster === item.cluster).map(x => x.ServerAddress).filter(x => x !== item.ServerAddress);
      }

      // const response = await request({
      //   method: 'GET',
      //   url: `/servers/acl?server=${serverName}`
      // });
      // console.log(response);
      // this.openedServer = item;
      //
      // try {
      //
      //   // console.log(res);

      //   //

      //   res.ACL.map((obj) => {
      //     this.usersOf = obj.Filter.map((user) => {
      //       return {
      //         user_name: user,
      //         role: obj.Role,
      //       };
      //     });
      //   });
      this.aclDialogVisible = true;
      // } catch (error) {
      //   console.log(error.message);
      // }
      // this.isLoading = false;
    },
    closeAclDialog() {
      this.aclDialogVisible = false;
      this.usersOf = [];
    },
    async reloadAclConfig(item) {
      let serverLink = item.ServerAddress;

      try {
        let res = await awsAuth.getServerConfig(serverLink);
        if (res) console.log("status 200");
      } catch (error) {
        console.log(error.message);
      }
    },
    customSort(items) {
      return items.sort((a,b) => a.cluster.localeCompare(b.cluster));
    },
  },
};
</script>

<style scoped>
.online {
  color: rgb(36, 99, 36);
  margin-right: 10px;
}
.offline {
  color: rgb(162, 36, 36);
}
.spinner {
  display: flex;

  align-items: center;
}
</style>

<style >
.v-row-group__header {
  background: #ffffff !important;
}
.table-cluster-edit {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    float: right;
    line-height: 24px;
}
.table-cluster-edit-text {
    font-size: 14px;
    text-decoration: underline;
}
.table-cluster-edit-icon {
  margin-left: 5px;
  font-size: 16px;
}
/*tbody tr:nth-of-type(even) {*/
/*    background-color: #ccc;*/
/*}*/
.table-group-header {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
}
.table-cluster-row {
  padding-left: 30px;
}

</style>
