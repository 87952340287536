<template>
  <div class="loadingio-spinner-spinner-6dsl6xnz6kg"><div class="ldio-8jei1ea8ols">
<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div></div>

</template>

<script>
export default {};
</script>

<style scoped>
@keyframes ldio-8jei1ea8ols {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-8jei1ea8ols div {
  left: 47px;
  top: 24px;
  position: absolute;
  animation: ldio-8jei1ea8ols linear 2.2222222222222223s infinite;
  background: #1eafc1;
  width: 6px;
  height: 12px;
  border-radius: 3px / 6px;
  transform-origin: 3px 26px;
}.ldio-8jei1ea8ols div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -2.0370370370370368s;
  background: #1eafc1;
}.ldio-8jei1ea8ols div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1.8518518518518516s;
  background: #1eafc1;
}.ldio-8jei1ea8ols div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -1.6666666666666665s;
  background: #1eafc1;
}.ldio-8jei1ea8ols div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -1.4814814814814814s;
  background: #1eafc1;
}.ldio-8jei1ea8ols div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -1.2962962962962963s;
  background: #1eafc1;
}.ldio-8jei1ea8ols div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -1.111111111111111s;
  background: #1eafc1;
}.ldio-8jei1ea8ols div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.9259259259259258s;
  background: #1eafc1;
}.ldio-8jei1ea8ols div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.7407407407407407s;
  background: #1eafc1;
}.ldio-8jei1ea8ols div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.5555555555555555s;
  background: #1eafc1;
}.ldio-8jei1ea8ols div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.37037037037037035s;
  background: #1eafc1;
}.ldio-8jei1ea8ols div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.18518518518518517s;
  background: #1eafc1;
}.ldio-8jei1ea8ols div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #1eafc1;
}
.loadingio-spinner-spinner-6dsl6xnz6kg {
  width: 28px;
  height: 28px;
  display: inline-block;
  overflow: hidden;
  /* background: #ffffff; */
}
.ldio-8jei1ea8ols {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.28);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-8jei1ea8ols div { box-sizing: content-box; }
/* generated by https://loading.io/ */
/* generated by https://loading.io/ */

</style>



