<template>
  <v-dialog
    v-model="intDialogVisible"
    max-width="50vw"
    max-height="80vh"
    content-class="edit-acl__dialog"
    persistent
  >
    <v-card class="edit-acl__card pa-4">
      <v-card-title>
        <p>
          Access Table :
          <span>{{ serverName ? serverName.toUpperCase() : "" }}</span>
        </p>
        <v-spacer></v-spacer>
        <v-btn icon color="gray">
          <v-icon :disabled="isLoading" @click="intDialogVisible = false" dark>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <div v-show="isOverSize" class="warning-size">The file exceeds the allowed size</div>
      <v-card-text>
        <!-- <ul style="margin-right: 0px" class="acl-user__table-header">
          <li>Username</li>
          <li>Guest</li>
          <li>Presenter</li>
          <li>Admin</li>
        </ul> -->
        <div class="acl-user__table-wrapper">
          <v-progress-circular
            v-if="isLoading"
            class="spinner"
            indeterminate
            color="#1eafc1"
            opacity
          ></v-progress-circular>
          <vue-json-editor
            v-else
            color="red"
            heigth="80vh"
            v-model="aclData"
            :expandedOnStart="true"
          ></vue-json-editor>

          <!-- <ul
            class="acl-user__table_column"
            v-else
            v-for="(user, index) in acl_users"
            :key="index"
          >
            <li>{{ user.user_name }}</li>
            <li>
              <v-radio-group v-model="user.role" row>
                <v-radio value="Guest"></v-radio>
                <v-radio value="Presenter"></v-radio>
                <v-radio value="Administrator"></v-radio>
              </v-radio-group>
            </li>
          </ul> -->
        </div>
      </v-card-text>

      <v-card-actions class="card-actions__wrapper" v-if="isAdmin">
        <v-btn
          :disabled="isLoading"
          color="primary"
          outlined
          width="50%"
          class="text-capitalize"
          @click="intDialogVisible = false"
          >Cancel</v-btn
        >
        <v-btn
          :disabled="isLoading"
          color="primary"
          width="50%"
          class="text-capitalize"
          intDialogVisible="false"
          @click="updateRoles"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import awsAuth from "@/cognito/aws-auth";
import request from "@/plugins/request";

// import { mapState } from "vuex";
import vueJsonEditor from "vue-json-editor";
import sizeof from "object-sizeof";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    serverName: {
      type: String,
      default: null,
    },
    serverAddress: {
      type: String,
      default: null,
    },
    users: {
      type: Array,
      default: null,
    },

    serverData: {
      type: Object,
    },
    clusterServers: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      user_role: null,
      acl_users: [],
      dataOfRoles: [],
      aclData: null,
      isLoading: true,
      isAdmin: false,
      isVersion: null,
      isOverSize: false,
      search: null,
      searchString: "",
      currentSearchIndex: -1,
      searchFilteredArray: [],
      searchNextButton: null,
      searchPrevButton: null,
    };
  },
  components: {
    vueJsonEditor,
  },
  mounted() {
    this.$nextTick(() => {
      this.getAclData();
    });
    this.isAdmin = awsAuth.isUserAdmin();
  },
  computed: {
    intDialogVisible: {
      get() {
        return this.dialogVisible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    setupSearchScroll() {
      const self = this;

      self.search = [].filter.call(document.getElementsByTagName('input'), el => el.closest('td'))[0];
      self.search.oninput = this.handleSearchInput;

      self.search.addEventListener('keydown', function(e) {
        if (e.keyCode === 13) {
          if (e.shiftKey) {
            self.scrollToPrev();
          } else {
            self.scrollToNext();
          }
        }
      });

      self.searchNextButton = document.getElementsByClassName('jsoneditor-next')[0];
      self.searchPrevButton = document.getElementsByClassName('jsoneditor-previous')[0];
      self.searchNextButton.onclick = function () {
        self.scrollToNext();
      };
      self.searchPrevButton.onclick = function () {
        self.scrollToPrev();
      }
    },

    scrollToNext() {
      this.searchFilteredArray = [].filter.call(document.getElementsByClassName('jsoneditor-string'), el => el.innerText.includes(this.searchString));

      if (this.searchFilteredArray && this.searchFilteredArray.length > 0 && this.searchString) {

        let higlighted = document.getElementsByClassName('jsoneditor-highlight-active');
        if (higlighted && higlighted.length > 0) {
          higlighted[0].classList.remove("jsoneditor-highlight-active");
        }

        if (this.currentSearchIndex + 1 === this.searchFilteredArray.length) {
          this.searchFilteredArray[0].classList.add('jsoneditor-highlight-active');
          this.scrollToElement(this.searchFilteredArray[0]);
          this.currentSearchIndex = 0;

        } else {
          this.searchFilteredArray[this.currentSearchIndex + 1].classList.add('jsoneditor-highlight-active');
          this.scrollToElement(this.searchFilteredArray[this.currentSearchIndex + 1]);
          this.currentSearchIndex += 1;
        }
      }
    },

    scrollToPrev() {
      this.searchFilteredArray = [].filter.call(document.getElementsByClassName('jsoneditor-string'), el => el.innerText.includes(this.searchString));

      if (this.searchFilteredArray && this.searchFilteredArray.length > 0 && this.searchString) {

        let higlighted = document.getElementsByClassName('jsoneditor-highlight-active');
        if (higlighted && higlighted.length > 0) {
          higlighted[0].classList.remove("jsoneditor-highlight-active");
        }

        if (this.currentSearchIndex <= 0) {
          this.searchFilteredArray[this.searchFilteredArray.length - 1].classList.add('jsoneditor-highlight-active');
          this.scrollToElement(this.searchFilteredArray[this.searchFilteredArray.length - 1]);
          this.currentSearchIndex = this.searchFilteredArray.length - 1;

        } else {
          this.searchFilteredArray[this.currentSearchIndex - 1].classList.add('jsoneditor-highlight-active');
          this.scrollToElement(this.searchFilteredArray[this.currentSearchIndex - 1]);
          this.currentSearchIndex -= 1;
        }
      }
    },

    scrollToElement(el) {
      el.scrollIntoView({ behavior: "auto" });
    },

    handleSearchInput(e) {
      this.currentSearchIndex = -1;
      this.searchString = e.target.value;
    },

    async getAclData() {
      const serverUrl = this.serverAddress;
      const response = await request({
        method: "GET",
        url: `/servers/acl?server=${serverUrl}`,
      });
      this.isLoading = false;
      if (response.data.ACL) {
        this.aclData = response.data.ACL;
        Object.seal(this.aclData);
        this.isVersion = response.data.Version;
      } else {
        this.aclData = "No data";
      }
      this.$nextTick(() => {
        this.setupSearchScroll();
      });
    },

    async updateRoles() {
      const dataUsers = {};
      this.isLoading = true;
      dataUsers.Version = this.isVersion;
      dataUsers.ACL = this.aclData;
      const serverUrl = this.serverAddress;
      if (sizeof(dataUsers) >= 1048576) {
        this.isOverSize = true;
      } else {
        try {
          let res = await awsAuth.updateDataRoles(serverUrl, dataUsers);
          console.log(res);

          await this.reloadClusterServers(this.clusterServers);
          this.isLoading = false;
          this.intDialogVisible = false;

        } catch (error) {
          this.isLoading = false;
          console.log(error.message);
        }
      }
    },

    async reloadClusterServers(servers) {
      servers.forEach(srv => {
        awsAuth.getServerConfig(srv);
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  width: 100%;
  height: 100%;
  font-size: 15px;
  position: relative;
  display: inline-block;
  padding: 10px;
}

span {
  color: #1eafc1;
}
.warning-size {
  color: red;
  font-size: 20px;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
